import './skip-link-focus-fix';
import AOS from 'aos';
import 'script-loader!slick-carousel';

(function($) {

	// simple accordions
	$('.simple-accordion-toggle').on('click', function() {
		$(this).next('.simple-accordion-toggled').slideToggle('fast');
		$(this).children('.arrow').toggleClass('arrow-down arrow-up');
	});

	$('.acf-80-20-accordion-toggle').on('click', function(){
		$(this).parent().parent().parent().next('.acf-80-20-accordion-toggled').slideToggle('fast');
		$(this).children().children('svg').toggleClass('arrow-up arrow-down');
		$(this).siblings('.more-less').toggleClass('more less');
	});

	$('.more-less').on('click', function(){
		$(this).prev().children().children('svg').toggleClass('arrow-up arrow-down');
		$(this).toggleClass('more less');
		$(this).parent().parent().parent().next('.acf-80-20-accordion-toggled').slideToggle('fast');
	});

	$('.accordion-toggle').on('click', function(){
		$(this).next('.accordion-toggled').slideToggle('fast');
		$(this).children('span.accordion-arrow').toggleClass('arrow-down arrow-up');
	});

	$(document).ready(function() {
		menuToggle();
		headerEffects();
		accordionShortcode();
		screenFeatures();
//		sliderContent();
		$(window).on('scroll', function(){
			headerEffects();
		});
		$(window).scroll();
	});

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-wrap").toggleClass('site-header__menu-wrap--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Header scroll effects
	function headerEffects() {
		var navbar = $('#masthead');

		var scrollPosition = $(window).scrollTop();

		if(scrollPosition > 10)
			navbar.addClass('site-header--scrolled');
		else
			navbar.removeClass('site-header--scrolled');
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

	// Screen features block
	function screenFeatures() {
		$('.screen-features__feature-toggle').on('click', function () {
			var imac    = $(this).closest('.screen-features__imac'),
			    feature = $(this).data('feature');

			imac.find('.screen-features__feature-image').removeClass('screen-features__feature-image--open');
			imac.find('.screen-features__feature-toggle').removeClass('screen-features__feature-toggle--open');

			$(this).addClass('screen-features__feature-toggle--open');
			imac.find('.screen-features__feature-image[data-feature=' + feature + ']').addClass('screen-features__feature-image--open');

		});
	}

	// featured testimonial slider
	$('.slider-content__slider').slick({
		autoplay: false,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		adaptiveHeight: true
	});
	$('.slider-content__slider ul.slick-dots').wrap("<div class='slider-content-nav-wrap' />");
	$('.slider-content__slider ul.slick-dots').before("<div class='slider-contenet-nav prev'><img src='/wp-content/themes/axearcade/img/prev-light.svg' /></div>");
	$('.slider-content__slider ul.slick-dots').after("<div class='slider-contenet-nav next'><img src='/wp-content/themes/axearcade/img/next-light.svg' /></div>");
	$('.slider-contenet-nav.prev').on('click', function(){
		$(this).parent().parent().parent().children('.slider-content__slider').slick('slickPrev');
	});
	$('.slider-contenet-nav.next').on('click', function(){
		$(this).parent().parent().parent().children('.slider-content__slider').slick('slickNext');
	});

	// acf 50 slider
	$('.slider-prev').on('click', function(){
		$(this).parent().prev().slick('slickPrev');
	});
	$('.slider-next').on('click', function(){
		$(this).parent().prev().slick('slickNext');
	});
	$('.acf-slides').slick({
		autoplay: false,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
	});

	// featured testimonial slider
	$('.featured-testimonial-nav-item.prev').on('click', function(){
		$(this).parent().prev().children('.featured-testimonials').slick('slickPrev');
	});
	$('.featured-testimonial-nav-item.next').on('click', function(){
		$(this).parent().prev().children('.featured-testimonials').slick('slickNext');
	});
	$('.featured-testimonials').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		dots: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		infinite: true,
		arrows: false,
		adaptiveHeight: true
	});

	// animate on scroll init
	AOS.init({ once: true, duration: 700 });

})(jQuery);